
export const numberOfLocations = [
    "1-5",
    "6-99",
    "100+"
];
export const concept = [
    "Quick Service Restaurants",
    "Fast Casual Restaurants",
    "Full Service Restaurants",
    "Not a Restaurant",
    "Virtual Brand/Ghost Kitchen Only",
    "Food Truck Only"
];
export const cuisine = [
    "Pizza",
    "Sandwiches",
    "Burgers",
    "Chicken",
    "Mexican",
    "Asian",
    "Mediterranean",
    "BBQ",
    "Seafood",
    "Beverage/Snack/Dessert",
    "Breakfast/Brunch",
    "Sushi"
]