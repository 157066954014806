import React, { useState, useEffect, useRef } from "react";
import TitleBar from "../../components/TitleBar";
import "./style.css";
import BackgroundImage from "../../assets/images/texting-skater-mycguld.jpg";
import RegistrationPhone from "../../assets/images/registration-phone.png";
import { useNavigate } from "react-router-dom";
import ChachedImage from "../../components/ChachedImage";
import { numberOfLocations, concept, cuisine } from "./Text";
var ie = require("ie-version");

const Registration = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [company, setCompany] = useState("");
  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState({
    "context": {
      "pageUri": "orderai.hungerrush.com",
      "pageName": "Registration",
      "sfdcCampaignId": "7011W000001g1taQAA",
    }
  });

  // Maps through the options from an external file to propogate the options
  const conceptOptions = 
    concept.map((item)=> {
    return (<option>{item}</option>)
  })
  const numberOfLocationsOptions = 
  numberOfLocations.map((item)=> {
  return (<option>{item}</option>)
  }) 
  const cuisineOptions = 
    cuisine.map((item)=> {
    return (<option>{item}</option>)
  }) 
  // The functions that set the state to send to the hubspot form
  const selectRestaurantSize = (e) => {
    setFormValues({...formValues, "fields": [...formValues.fields, { "obejectTypeId": "0-1", "name": "restaurant_size", "value": e?.target.value}]});
  };
  const selectConcept = (e) => {
    setFormValues({...formValues, "fields": [...formValues.fields, { "obejectTypeId": "0-1", "name": "concept", "value": e?.target.value}]});
  };
  const selectCuisine = (e) => {
    setFormValues({...formValues, "fields": [...formValues.fields, { "obejectTypeId": "0-1", "name": "cuisine", "value":e?.target.value}]});
  };

  const navigate = useNavigate();

  
  const handleButtonClick = (e) => {

    e.preventDefault();
    formValues.fields.push({ "obejectTypeId": "0-1", "name": "lastname", "value": lastName })
    formValues.fields.push({ "obejectTypeId": "0-1", "name": "firstname", "value": firstName })
    formValues.fields.push({ "obejectTypeId": "0-1", "name": "email", "value": emailId })
    formValues.fields.push({ "obejectTypeId": "0-1", "name": "phone", "value": phoneNumber })
    var finalData = JSON.stringify(formValues);
    navigate("/order", {
      state: {
        phoneNumber,
        firstName,
      },
    });

    fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_PORTAL_ID_PROD}/${process.env.REACT_APP_FORM_ID_PROD}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: finalData,
    }).then((res) => {
      console.log(res);
    });
  };

 


  return (
    <div className="page-wrap">
      <TitleBar />
      <ChachedImage className="d-none" alt="" src={BackgroundImage} />
      <ChachedImage className="d-none" alt="" src={RegistrationPhone} />
      <div
        className="content-registration"
        style={{
          backgroundImage: `url(${BackgroundImage})`,
        }}
      >
        <div className="form-wrap">
          <div className="black-background">
            <p className="title-text">ORDERAI TEXT</p>
            <div className="description-text">
              <p>See HungerRush 360</p>

              <p> OrderAI Text in action</p>
            </div>
          </div>

          <div className="registration-form-card">
            <p>We just need a bit of information before you start the demo.</p>

            <form className="registration-form" onSubmit={handleButtonClick}>
              <div className="input-wrap">
                <input
                  placeholder="First Name*"
                  className="small-input"
                  onChange={(e) => {

                    setFirstName(e.target.value);
                    setFormValues({...formValues, "fields": [{ "obejectTypeId": "0-1", "name": "firstname", "value": e.target.value }] })
                    }}
                  required
                />
                <input 
                placeholder="Last Name*" 
                className="small-input"
                onChange={(e) => {
                    setLastName(e.target.value);
                    setFormValues({...formValues, "fields": [{ "obejectTypeId": "0-1", "name": "lastname", "value": e.target.value }] })
                    }}
                required
                />
              </div>
              <input
                  placeholder="Phone Number (xxx-xxx-xxxx)*"
                  className="large-input"
                  type="tel"
                  // pattern="\+[0-9]{11,11}"
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                    setFormValues({...formValues, "fields": [...formValues.fields, { "obejectTypeId": "0-1", "name": "phone", "value": e.target.value }] })
                    e.target.setCustomValidity('')
                  }}
                  minLength="10"
                  maxLength="10"
                  onInvalid={e =>e.target.setCustomValidity('Please enter a valid phone number')}
                  required
                />
              <input 
              placeholder="Email*" 
              className="large-input" 
              onChange={(e) => {
                setEmailId(e.target.value);
                setFormValues({...formValues, "fields": [...formValues.fields, { "obejectTypeId": "0-1", "name": "email", "value": e.target.value }] })
                e.target.setCustomValidity('')
                }}
              onInvalid={e => e.target.setCustomValidity('Please enter a valid email address')}
              type="email"
              required
              />
              <input 
              placeholder="Company*" 
              className="large-input"
              onChange={(e) => {
                     setCompany(e.target.value);
                    setFormValues({...formValues, "fields": [{ "obejectTypeId": "0-1", "name": "company", "value": e.target.value }] })
                    }}
              required
              />
              <select name="concept" className="large-input" onChange={selectRestaurantSize} required>
                <option value='' disabled selected># of Locations*</option>
                {numberOfLocationsOptions}
              </select>
              <select name="concept" className="large-input" onChange={selectConcept} required>
                <option value='' disabled selected>Concept*</option>
                {conceptOptions}
              </select>
              <select name="concept" className="large-input" onChange={selectCuisine} required>
                <option value='' disabled selected>Cuisine*</option>
                {cuisineOptions}
              </select>
              <div className="submit-wrap">
                <button type="submit">View Demo</button>
              </div>
            </form>
          </div>
          <div className="phone-wrap">
            <div
              className="registration-phone-img"
              style={{ backgroundImage: `url(${RegistrationPhone})` }}
            >
              <p className="registration-phone-number">(555) 555-1212</p>
              <div className="text-messages-wrap wrap">
                <div className="colored-message-box first-message-box block_1">
                  <p className="colored-message-text">
                    1 large cheese and 1 large pepperoni for delivery
                  </p>
                </div>

                <div className="white-message-box second-message-box block_2">
                  <p className="white-message-text">
                    Joe’s Pizza. Please review:
                    <br />
                    <br />
                    Delivery to 123 South Main St
                    <br />
                    Ann Arbor, MI 48103
                    <br />
                    - 1 Large pizza, cheese
                    <br />
                    - 1 Large pizza, pepperoni
                    <br />
                    Total w/ tax: $21.35
                    <br />
                  </p>
                </div>

                <div className="white-message-box third-message-box block_3">
                  <p className="white-message-text">
                    Make Edits:
                    <br />
                    <br />
                    txtordr.com/myordr/dkfh2334
                    <br />
                    <br />
                    To Pay: Type your 4-Digit Text PIN to charge card ending in
                    1111
                    <br />
                  </p>
                </div>

                <div className="colored-message-box fourth-message-box block_4 ">
                  <p className="colored-message-text">5333</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
